export const scrollToLink = link => {
  const headerHeight = document.getElementById('header-inside')?.offsetHeight;

  window.scroll({
    top: document?.getElementById(link)
      ? document?.getElementById(link).offsetTop - headerHeight
      : 0,
    behavior: 'smooth',
  });
};
