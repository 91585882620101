import React from 'react';

import { Col, Container, Row } from 'react-bootstrap';

import { useTranslation } from 'gatsby-plugin-react-i18next';

import { FormEl as Form } from '@components/Form';

import { SectionHeading } from '@common/components/Text';
import { Map, Wrapper } from './styles';

export const Section = () => {
  const { t } = useTranslation();

  return (
    <Wrapper id="contact">
      <Container>
        <Row className="justify-content-center">
          <Col lg="6">
            <SectionHeading
              light
              title={t('section.contact.title')}
              subtitle={t('section.contact.subtitle')}
            />

            <p>{t('section.contact.content')}</p>

            <ul>
              <li>Firma: L&L Motors d.o.o.</li>
              <li>Adresa: Viškovo 156, 51216 Viškovo, Hrvatska/Kroatien</li>
              <li>OIB: 98990415683</li>
              <li>
                Mob: <a href="tel:+385989217793">+385 98 921 7793</a>
              </li>
              <li>
                Email: <a href="mailto:elandel.motors@gmail.com">elandel.motors@gmail.com</a>
              </li>
            </ul>
          </Col>

          <Col lg="6">
            <div className="py-3 d-lg-none"></div>
            <Form />
          </Col>

          <Col sm="12">
            <a
              href="https://www.google.com/maps/search/Vi%C5%A1kovo+156,+Hrvatska/@45.3677642,14.3769512,15z/data=!3m1!4b1"
              target="_blank"
              rel="noreferrer"
            >
              <Map src="/images/map.webp" alt="L&L Motors lokacija" loading="lazy" />
            </a>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};
