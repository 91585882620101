import React from 'react';

import { Wrapper, SectionTitle, SectionLabel } from '@components/Text';
export * from './styles';

export const SectionHeading = ({
  title,
  subtitle,
  light,
}: {
  title: string;
  subtitle: string;
  light?: boolean;
}) => (
  <Wrapper data-light={light}>
    {title && <SectionTitle>{title}</SectionTitle>}
    {subtitle && <SectionLabel>{subtitle}</SectionLabel>}
  </Wrapper>
);
