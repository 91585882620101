import styled from 'styled-components';

import { OnePageSection } from '@components/OnePageSection';

export const Wrapper = styled(OnePageSection)`
  position: relative;

  img {
    object-fit: cover;
  }
`;
