import styled from 'styled-components';

import { Colors } from '@common/theme';
import { OnePageSection } from '@components/OnePageSection';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import { Swiper, SwiperSlide } from 'swiper/react';

export const Slider = styled(Swiper)`
  --swiper-pagination-color: ${Colors.primary};
  pointer-events: none;

  .swiper-wrapper {
    transition-timing-function: linear;
  }
`;

export const Slide = styled(SwiperSlide)``;

export const Wrapper = styled(OnePageSection)`
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    height: 80px;
    object-fit: contain;
    filter: grayscale(100);
    opacity: 0.8;
    margin: auto;
    width: 90%;
  }
`;
