import styled from 'styled-components';

import { Colors, Breakpoints } from '@common/theme';

export const Logo = styled.img`
  width: 150px;
  height: auto;
  /* filter: grayscale(1); */

  ${Breakpoints.lg`
    width: 215px;
  `}
`;

export const Wrapper: any = styled.header`
  position: fixed;
  z-index: 50;
  left: 0;
  right: 0;

  .container {
    border-bottom: 1px solid;
    border-bottom-color: ${({ ontop }: any) => (!ontop ? Colors.primary : 'transparent')};
    transition: max-width ease-in-out 500ms, margin ease-in-out 300ms,
      border-bottom ease-in-out 500ms 500ms;
    max-width: ${({ ontop }: any) => (!ontop ? '100%' : '1176px')};
    margin: ${({ ontop }: any) => (!ontop ? '0 auto' : '10px auto')};
    background-color: ${Colors.white};
  }
`;

export const Inside = styled.div`
  justify-content: space-between;
  padding: 0.7rem 0;
  display: flex;
  align-items: center;

  ${Breakpoints.lg`
   padding: 0;
  `}
`;
