import { useTranslation } from 'gatsby-plugin-react-i18next';

export const useHook = () => {
  const { t } = useTranslation();

  const clients: {
    title: string;
    image: string;
  }[] = t('section.clients', { returnObjects: true });

  return { clients };
};
