import * as React from 'react';
// import { useEffect } from 'react';
import { Helmet as Seo } from 'react-helmet';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { Colors } from '@common/theme';
import { Layout } from '@layout/default';

import { Section as Hero } from '@sections/hero';
import { Section as About } from '@sections/about';
import { Section as Contact } from '@sections/contact';
import { Section as Ordering } from '@sections/ordering';
import { Section as Gallery } from '@sections/gallery';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const IndexPage = () => {
  const { i18n, t } = useTranslation();

  return (
    <Layout>
      <Seo htmlAttributes={{ lang: i18n.language }}>
        <meta charSet="utf-8" />
        <title>{t('meta.title')}</title>
        <link rel="canonical" href={t('meta.siteUrl')} />
        <meta name="description" content={t('meta.description')}></meta>
        <link rel="icon" type="image/x-icon" href="/favicon.webp" />
        <meta name="theme-color" content={Colors.primary} />
        <meta property="og:image" content={`${t('meta.siteUrl')}/ogp.webp`} />
        <meta property="og:title" content={t('meta.title')} />
        <meta property="og:url" content={`${t('meta.siteUrl')}`} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={t('meta.description')} />
        <script src="https://www.google.com/recaptcha/api.js?render=6LdercIkAAAAADPvoyFw7Oyju1Ujd9VfE3UeZ6bm"></script>
      </Seo>

      <Hero />
      <About />
      <Ordering />
      <Gallery />
      <Contact />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
