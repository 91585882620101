import React from 'react';

import { Section as Clients } from '@sections/clients';
import { Col, Container, Row } from 'react-bootstrap';

import { useTranslation } from 'gatsby-plugin-react-i18next';

import { SectionHeading } from '@common/components/Text';
import { Content, Wrapper, Disclaimer, LinkGrayscale } from './styles';
import { icons } from '@assets/partners';

export const Section = () => {
  const { t } = useTranslation();

  return (
    <Wrapper id="o-nama">
      <Container>
        <Row className="justify-content-center">
          <Col lg="8">
            <SectionHeading
              title={t('section.about.title')}
              subtitle={t('section.about.subtitle')}
            />
            <Content dangerouslySetInnerHTML={{ __html: t('section.about.content') }}></Content>
            <Row className="align-items-center justify-content-center mt-5">
              <Col sm="12">
                <Disclaimer
                  dangerouslySetInnerHTML={{ __html: t('section.about.disclaimer') }}
                ></Disclaimer>
              </Col>

              <Col xs="6" md="4">
                <LinkGrayscale href="https://www.mobile.de/" target="_blank" rel="noreferrer">
                  <img alt="Mobile.de" className="w-100" src={icons.mobilede} />
                </LinkGrayscale>
              </Col>

              <Col xs="6" md="4">
                <LinkGrayscale href="https://www.autoscout24.hr" target="_blank" rel="noreferrer">
                  <img alt="AutoScout24" className="w-100" src={icons.autoscout24} />
                </LinkGrayscale>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <div className="pt-5">
        <Clients />
      </div>
    </Wrapper>
  );
};
