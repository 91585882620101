import styled from 'styled-components';

import { Colors } from '@common/theme/Colors';
import { Fonts } from '@common/theme/Fonts';
import { Transitions } from '@common/theme/Transitions';
import { Positions } from '@common/theme/Positions';
import { Wrapper as SectionWrapper } from '@common/sections/ordering/styles';
import { Breakpoints } from '@common/theme/Breakpoints';

export const Wrapper = styled.a`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem;
  color: ${Colors.white};
  transition: ${Transitions.default};
  transition-delay: 400ms;
  opacity: 0;
  cursor: pointer;

  ${Breakpoints.lg`
   flex-direction: row;
   align-items: start;
  `}

  ${SectionWrapper}.visible & {
    opacity: 1;
  }

  &:after {
    content: '';
    ${Positions.absoluteFull}
    top: auto;
    height: 2px;
    background-color: ${Colors.neutral100};
    transition: ${Transitions.default};
    opacity: 0;
  }

  &:hover {
    background-color: #e9e9e9;
  }

  &:hover:after {
    opacity: 1;
    background-color: ${Colors.primary};
  }
`;

export const Title = styled.h3`
  display: block;
  position: relative;
  color: ${Colors.black};
  text-decoration: none;
  font-weight: ${Fonts.weight.regular};
  font-size: 18px;
  margin-bottom: 0.5rem;
`;

export const Content = styled.div`
  color: ${Colors.black};
  text-decoration: none;
  font-size: 14px;

  p {
    margin-bottom: 0;
  }

  ol {
    padding: 0;
    padding-left: 0.8rem;
  }

  * {
    font-size: inherit;
  }
`;

export const Icon = styled.img`
  height: 100px;
  width: 100px;
  margin-bottom: 1rem;
`;
