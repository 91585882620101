import React from 'react';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import { FiAlignRight, FiX } from 'react-icons/fi';
import { useHook } from './hook';

import { Colors } from '@common/theme';

import { ContactTrigger, List, Trigger } from './styles';

export const Menu = () => {
  const { t } = useTranslation();

  const { isMenuVisible, triggerHandle, onLinkClickHandle } = useHook();

  return (
    <>
      <Trigger onClick={triggerHandle}>
        {isMenuVisible ? (
          <FiX color={Colors.primary} size={30} />
        ) : (
          <FiAlignRight color={Colors.primary} size={30} />
        )}
      </Trigger>

      <List showMenu={isMenuVisible}>
        {t('menu', { returnObjects: true }).map((item, key) => (
          <li key={key}>
            <a onClick={onLinkClickHandle(item.link)} href={item.link}>
              {item.title}
            </a>
          </li>
        ))}
        <li>
          <ContactTrigger onClick={onLinkClickHandle('contact')} href="#contact">
            {t('global.contact')}
          </ContactTrigger>
        </li>
      </List>
    </>
  );
};
