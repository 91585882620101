import { useState } from 'react';
import { scrollToLink } from '@hooks/scrollToLink';

export const useHook = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);

  const triggerHandle = () => {
    setMenuVisible(!isMenuVisible);
  };

  const onLinkClickHandle = link => e => {
    e.preventDefault();

    setMenuVisible(false);
    scrollToLink(link);
  };

  return { isMenuVisible, triggerHandle, onLinkClickHandle };
};
