import React from 'react';

import { useTranslation } from 'gatsby-plugin-react-i18next';

import { Wrapper } from './styles';

import { Slider } from './Slider';

export const Section = () => {
  const { t } = useTranslation();

  return (
    <Wrapper id="home">
      <Slider items={t('section.hero', { returnObjects: true })} />
    </Wrapper>
  );
};
