import styled from 'styled-components';

import { Breakpoints, Colors, Positions } from '@common/theme';

import { OnePageSection } from '@components/OnePageSection';

export const Wrapper = styled(OnePageSection)`
  position: relative;
  background-color: ${Colors.blue};
  min-height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .swiper {
    ${Positions.absoluteFull};
    --swiper-navigation-color: ${Colors.white};
  }

  ${Breakpoints.lg`
    height: 80vh;
  `}
`;
