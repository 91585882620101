/* eslint-disable @typescript-eslint/no-var-requires */
import React from 'react';

import { Wrapper, Content, Title, Icon } from './styles';

import { scrollToLink } from '@hooks/scrollToLink';

export const Card = ({
  item,
  number,
}: {
  item: {
    title: string;
    content: string;
  };
  number: number;
}) => {
  const onCardClickHandle = (e: any) => {
    e.preventDefault();
    scrollToLink('contact');
  };

  return (
    <Wrapper onClick={onCardClickHandle} href="#contact">
      <Icon
        src={require(`@assets/ordering/${number - 1}.webp`)?.default}
        alt={`ordering-${number}`}
        loading="lazy"
      />
      <div className="ms-3">
        <Title>
          {number}.&nbsp;{item.title}
        </Title>
        <Content dangerouslySetInnerHTML={{ __html: item.content }} />
      </div>
    </Wrapper>
  );
};
