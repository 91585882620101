import React from 'react';

// import Swiper component, todo: move this into the styles.jsx
import { Autoplay } from 'swiper';
import { SwiperSlide } from 'swiper/react';

import { useHook } from './hook';
import { Slider, Wrapper } from './styles';

export const Section = () => {
  const { clients } = useHook();

  return (
    <Wrapper id="usluge">
      <Slider
        modules={[Autoplay]}
        loop
        loopFillGroupWithBlank
        autoplay={{
          delay: 100,
          disableOnInteraction: false,
        }}
        speed={6000}
        spaceBetween={10}
        breakpoints={{
          0: { slidesPerView: 2.2 },
          1024: { slidesPerView: 10 },
        }}
      >
        {clients.map((item: any) => (
          <SwiperSlide key={item.image}>
            <img src={`/images/carlogo/${item.image}`} alt={item.name} loading="lazy" />
          </SwiperSlide>
        ))}
      </Slider>
    </Wrapper>
  );
};
