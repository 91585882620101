import styled from 'styled-components';
import { Form } from 'formik';
import { Colors } from '@common/theme';

export const FormikForm = styled(Form)`
  .form-control {
    border-radius: 0;
    border: 0;
    background-color: #292c2f;
    color: ${Colors.white};

    &:focus {
      background-color: #212325;
      color: ${Colors.white};
    }
  }

  .form-floating textarea {
    height: auto;
  }
`;

export const SuccessfullMessage = styled.div`
  text-align: center;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 1rem;
`;

export const GoogleDisclaimer = styled.div`
  font-size: 10px;
  padding: 0.5rem 0;

  a {
    font-size: 10px;
  }
`;
