import styled from 'styled-components';

import { Colors, Fonts, Breakpoints, Positions } from '@common/theme';

// Global section components
export const SectionTitle = styled.h2`
  font-weight: ${Fonts.weight.light};
  font-size: 25px;
  margin-bottom: 12px;
  line-height: 1;

  ${Breakpoints.lg`
    font-size: 30px;
  `}
`;

export const SectionLabel = styled.div`
  position: relative;
  font-weight: ${Fonts.weight.light};
  color: ${Colors.neutral};
  font-size: 12px;
  line-height: 1.7;
`;

export const Wrapper = styled.div`
  position: relative;
  margin-bottom: 2rem;
  text-align: center;
  padding-bottom: 0.7rem;

  &:after {
    content: '';
    ${Positions.absoluteFull}
    top: auto;
    width: 100px;
    height: 3px;
    bottom: 0;
    margin: 0 auto;
    background-color: ${(props: any) => (!props['data-light'] ? Colors.primary : Colors.white)};
  }

  ${SectionLabel} {
    color: ${(props: any) => (!props['data-light'] ? Colors.black : Colors.white)};
  }

  ${SectionTitle} {
    color: ${(props: any) => (!props['data-light'] ? Colors.black : Colors.white)};
  }
`;
