import { Footer } from '@common/components/Footer';
import { Header } from '@common/components/Header';
import CookieConsent from '@common/components/CookieConsent';
import React from 'react';
import { Main } from './styles';

export const Layout = ({ children, hideMenu = false }: any) => (
  <>
    <Header hideMenu={hideMenu} />
    <Main>{children}</Main>
    <Footer />
    <CookieConsent />
  </>
);
