import styled from 'styled-components';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';

import { Colors } from '@common/theme/Colors';
import { Positions } from '@common/theme/Positions';
import { Fonts } from '@common/theme/Fonts';
import { Breakpoints } from '@common/theme/Breakpoints';

export const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;

    img {
      object-position: 50% 40%;
    }
  }
`;

export const Content = styled.div`
  ${Positions.absoluteFull};
  top: 250px;
  z-index: 2;
  display: flex;
  align-items: center;

  ${Breakpoints.lg`
    top: 0;
  `}
`;

export const Title = styled.h2`
  padding: 1rem;
  background-color: ${Colors.primary};
  width: 100%;
  color: ${Colors.white};
  text-transform: uppercase;
  font-weight: ${Fonts.weight.bold};
  margin-bottom: 0;
`;

export const Subtitle = styled.div`
  position: relative;
  padding: 0.3rem 0.7rem;
  font-size: 13px;
  display: inline-block;
  background-color: ${Colors.primaryDarker};
  color: ${Colors.white};
  text-transform: uppercase;
  font-weight: ${Fonts.weight.bold};

  > svg {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -0.7rem;
    color: ${Colors.primaryDarker};
  }
`;

export const SlideContent = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  padding: 0 5px;
  overflow: hidden;
  color: ${Colors.white};
  font-size: 13px;
  background-color: rgba(${Colors.blackRGB}, 0.7);
`;
