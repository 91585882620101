import React, { useEffect, useState } from 'react';

import { Menu } from '@components/Header/Menu';

import { Container, Row, Col } from 'react-bootstrap';

import { Wrapper, Inside, Logo } from './styles';
import { useTranslation } from 'react-i18next';

export const Header = ({ hideMenu }: { hideMenu: boolean }) => {
  const [onTop, setOnTop] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setOnTop(!window.pageYOffset);
    });
  }, []);

  const onLogoClick = e => {
    e.preventDefault();
    window.scroll({ top: 0, behavior: 'smooth' });
  };

  return (
    <Wrapper ontop={onTop}>
      <Container style={{ position: 'sticky' }}>
        <Row>
          <Col>
            <Inside id="header-inside">
              <a href="/" onClick={onLogoClick}>
                <Logo src="/images/logo.webp" alt={t('meta.sitename')} />
              </a>
              {!hideMenu && <Menu />}
            </Inside>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};
