import React from 'react';
import { handleViewport } from 'react-in-viewport';

import { Trigger } from './styles';
import { setViewportActiveLink } from './hook';

// Viewport handlear alongside with the link class trigger
const InViewportTrigger = ({ inViewport, forwardedRef, enterCount }: any) => {
  const section = forwardedRef.current?.closest('section');
  if (enterCount === 1) section.classList.add('visible');
  if (section?.id) {
    setViewportActiveLink(forwardedRef.current.closest('section').id, inViewport);
  }
  return <Trigger ref={forwardedRef} />;
};

const ViewportBlock = handleViewport(InViewportTrigger);

// Exported section ready for reuse
export const OnePageSection = ({ id, children, ...rest }: { id: string; children: any }) => (
  <section id={id} {...rest}>
    <ViewportBlock />
    {children}
  </section>
);
