import styled from 'styled-components';

import { Breakpoints, Transitions } from '@common/theme';
import { SectionTitle, SectionLabel } from '@components/Text/styles';
import { OnePageSection } from '@components/OnePageSection';

export const Wrapper = styled(OnePageSection)`
  position: relative;
  padding: 30px 0;
  overflow: hidden;
  opacity: 0;
  transform: translateY(-1rem);
  transition: ${Transitions.default};

  &.visible {
    opacity: 1;
    transform: none;
  }

  ${Breakpoints.lg`
    padding: 50px 0;
  `}
`;

export const Title = styled(SectionTitle)`
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

export const Subtitle = styled(SectionLabel)``;

export const Content = styled.p`
  margin-bottom: 0;
  font-size: 14px;
  text-align: center;
`;

export const Disclaimer = styled.p`
  font-size: 17px;
  text-align: center;
`;

export const LinkGrayscale = styled.a`
  filter: grayscale(100);
  transition: filter ease-in-out 250ms;

  &:hover {
    filter: none;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-bottom: 100%;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 60px;

  img {
    position: absolute;
    overflow: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }
`;
