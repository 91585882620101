import React from 'react';

import { Col, Container, Row } from 'react-bootstrap';

import { useTranslation } from 'gatsby-plugin-react-i18next';

// import Swiper component, todo: move this into the styles.jsx
import { Pagination } from 'swiper';

import { SectionHeading } from '@common/components/Text';
import { SwiperSlide } from 'swiper/react';
import { Card } from './card';
import { useHook } from './hook';
import { Slider, Wrapper } from './styles';

export const Section = () => {
  const { t } = useTranslation();
  const {
    ordering,
  }: {
    ordering: any;
  } = useHook();

  return (
    <Wrapper id="koraci-narudzbe-vozila">
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col lg="8">
            <SectionHeading
              title={t('section.ordering.title')}
              subtitle={t('section.ordering.subtitle')}
            />
          </Col>

          <Col xs="12" className="mt-4">
            <Slider
              modules={[Pagination]}
              spaceBetween={3}
              slidesPerView={1.2}
              pagination={{ clickable: true }}
              breakpoints={{
                700: { slidesPerView: 2.2 },
                1024: { slidesPerView: 4 },
              }}
            >
              {ordering.steps.map((item, key) => (
                <SwiperSlide key={item.title + key}>
                  <Card item={item} number={key + 1} />
                </SwiperSlide>
              ))}
            </Slider>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};
