import React from 'react';

import { Button } from '@components/Button';
import { Formik } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Col, Row } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { useHook } from './hook';
import { FormikForm, SuccessfullMessage, GoogleDisclaimer } from './styles';
import Spinner from 'react-bootstrap/Spinner';

export const FormEl = () => {
  const { t } = useTranslation();
  const { schema, initialValues, onFormSubmit } = useHook();

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onFormSubmit}>
      {formik => {
        const { values, errors, touched, handleChange, isSubmitting, formSubmited }: any = formik;

        return (
          <FormikForm>
            <Row>
              <Form.Floating>
                <FloatingLabel label={t<string>('section.contact.form.fullName')} className="mb-1">
                  <Form.Control
                    value={values.fullName}
                    onChange={handleChange}
                    name="fullName"
                    placeholder="&nbsp;"
                    isInvalid={errors.fullName && touched.fullName && errors.fullName}
                    isValid={!errors.fullName && touched.fullName && !errors.fullName}
                  />
                  {errors.fullName && touched.fullName && errors.fullName && (
                    <Form.Control.Feedback type="invalid">{errors.fullName}</Form.Control.Feedback>
                  )}
                </FloatingLabel>

                <FloatingLabel label={t<string>('section.contact.form.email')} className="mb-1">
                  <Form.Control
                    value={values.email}
                    onChange={handleChange}
                    placeholder="&nbsp;"
                    name="email"
                    isInvalid={errors.email && touched.email && errors.email}
                    isValid={!errors.email && touched.email && !errors.email}
                  />

                  {errors.email && touched.email && errors.email && (
                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                  )}
                </FloatingLabel>

                <FloatingLabel label={t<string>('section.contact.form.message')} className="mb-1">
                  <Form.Control
                    as="textarea"
                    rows={4}
                    value={values.message}
                    onChange={handleChange}
                    placeholder="&nbsp;"
                    isInvalid={errors.message && touched.message && errors.message}
                    isValid={!errors.message && touched.message && !errors.message}
                    name="message"
                  />
                  {errors.message && touched.message && errors.message && (
                    <Form.Control.Feedback type="invalid">{errors.message}</Form.Control.Feedback>
                  )}
                </FloatingLabel>

                <GoogleDisclaimer className="text-muted">
                  This site is protected by reCAPTCHA and the Google <br />{' '}
                  <a href="https://policies.google.com/privacy">Privacy Policy</a> and {'  '}
                  <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                </GoogleDisclaimer>
              </Form.Floating>

              <Col>
                {formSubmited ? (
                  <SuccessfullMessage>
                    Upit je uspješno poslan. <br></br> Odogovoriti ćemo u nakraćem mogućem roku.
                  </SuccessfullMessage>
                ) : (
                  <button type="submit" className="clear-default-styles w-100">
                    <Button className="w-100 pe-none" href="#">
                      {t('section.contact.form.button')}
                      {isSubmitting && (
                        <Spinner animation="border" variant="light" size="sm" className="ms-2" />
                      )}
                    </Button>
                  </button>
                )}
              </Col>
            </Row>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
