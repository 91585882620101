import styled from 'styled-components';

export const Trigger = styled.div`
  position: absolute;
  top: 20%;
  right: 0;
  left: 0;
  height: 10px;
  z-index: 10;
  background-color: transparent;
  pointer-events: none;
`;
