import styled from 'styled-components';

import { Colors } from '@common/theme';

export const Main = styled.main`
  color: ${Colors.default};

  a {
    color: inherit;

    &:hover {
      color: ${Colors.primary};
    }
  }

  .clear-default-styles {
    -webkit-appearance: none;
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    overflow: visible;
    text-transform: none;
    border: none;
    padding: 0;
  }

  .cookie-content a {
    text-decoration: none;
    font-size: 13px;
    color: ${Colors.white};
    font-weight: 900;
  }
  .cookie-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    width: 100%;
  }

  *::selection {
    color: ${Colors.white};
    background-color: ${Colors.primaryDarker};
  }
`;
