import styled from 'styled-components';

import { Colors } from '@common/theme';
import { OnePageSection } from '@common/components/OnePageSection';

export const Wrapper = styled(OnePageSection)`
  position: relative;
  padding: 40px 0;
  background-color: #353a3d;
  color: ${Colors.white};

  ul {
    list-style-position: inside;
  }
`;

export const Map = styled.img`
  width: 100%;
  max-height: 11rem;
  object-fit: cover;
  margin-top: 2rem;
  object-position: 0 37%;
`;
