import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { useTranslation } from 'gatsby-plugin-react-i18next';

import { Copyright, Wrapper } from './styles';

const today = new Date();

export const Footer = () => {
  const { t } = useTranslation();

  const onLogoClick = e => {
    e.preventDefault();
    window.scroll({ top: 0, behavior: 'smooth' });
  };

  return (
    <Wrapper>
      <Container>
        <Row className="justify-content-between">
          <Col className="text-center">
            <Copyright>
              © Copyright {today.getFullYear()}. {t('section.footer.copyright')}
            </Copyright>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};
