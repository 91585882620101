import styled from 'styled-components';
import { Breakpoints, Colors, Transitions } from '@common/theme';
import { Button } from '@components/Button';

export const Trigger = styled.div`
  cursor: pointer;

  ${Breakpoints.lg`
    display: none;
  `}
`;

export const ContactTrigger = styled(Button)`
  padding: 8px 12px !important;
  background-color: ${Colors.primary};
`;

export const List: any = styled.ul`
  position: absolute;
  left: 0;
  top: 100%;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  width: 100%;
  background-color: ${Colors.gray};
  height: calc(100vh - 42.2px); // maybe provide dynamic value?
  justify-content: center;
  transition: opacity ${Transitions.default};
  opacity: ${({ showMenu }) => (showMenu ? 1 : 0)};
  pointer-events: ${({ showMenu }) => (showMenu ? 'all' : 'none')};

  ${Breakpoints.lg`
    position: relative;
    flex-direction: row;
    opacity: 1;
    pointer-events: all;
    width: auto;
    height: auto;
    background-color: transparent;
    align-items: center;
  `}

  li {
    text-align: center;

    ${ContactTrigger} {
      color: ${Colors.white};
      padding-top: 7px;
      padding-bottom: 7px;

      ${Breakpoints.lg`
        margin-left: 10px;
      `}
    }

    a {
      display: block;
      padding: 0.6rem;
      font-size: 20px;
      text-decoration: none;
      color: ${Colors.neutral100};
      transition: color ${Transitions.default}, border-color ${Transitions.default};
      font-size: 14px;
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      border: 5px solid transparent;

      &:hover {
        color: ${Colors.neutral};
        border-top-color: ${Colors.neutral100};
      }

      &.active {
        color: ${Colors.white};

        ${Breakpoints.lg`
          color: ${Colors.neutral};
          border-top-color: ${Colors.primary};
        `}
      }

      ${Breakpoints.lg`
        padding: 1.5rem 1rem;
        color: ${Colors.neutral100};
      `}

      ${Breakpoints.lg`
      `}
    }
  }
`;
