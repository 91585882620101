import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useState } from 'react';
import * as Yup from 'yup';
// import axios from 'axios'

export const useHook = () => {
  const { t } = useTranslation();
  const [formSubmited, setFormSubmited] = useState<boolean>(false);

  const schema: any = Yup.object().shape({
    fullName: Yup.string().required(t('section.contact.form.validation.required')),
    email: Yup.string()
      .email(t('section.contact.form.validation.email'))
      .required(t('section.contact.form.validation.required')),
    message: Yup.string().required(t('section.contact.form.validation.required')),
  });

  const initialValues = {
    fullName: '',
    email: '',
    message: '',
  };

  const onFormSubmit = (values: any) => {
    const formData = new FormData();
    const { fullName, email, message } = values;

    const formStates = {
      fullName: fullName,
      // cellphone: cellphone,
      email: email,
      message: message,
    };

    for (const i in formStates) {
      formData.append(i, formStates[i]);
    }

    grecaptcha.ready(function () {
      grecaptcha
        .execute('6LdercIkAAAAADPvoyFw7Oyju1Ujd9VfE3UeZ6bm', { action: 'submit' })
        .then(async () => {
          await fetch('/send.php', {
            method: 'POST',
            body: formData,
          })
            .then(res => res.json())
            .then(res => {
              console.log(res);
              setFormSubmited(true);
            })
            .catch(e => {
              console.log('e', e);
            });
        });
    });
  };

  return { schema, initialValues, onFormSubmit, formSubmited };
};
