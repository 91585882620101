import styled from 'styled-components';

import { Colors } from '@common/theme';

export const Wrapper = styled.footer`
  padding: 8px 0;
  text-align: center;
  background-color: ${Colors.gray};
  color: ${Colors.neutral100};
`;

export const Copyright = styled.div`
  font-size: 12px;
  font-weight: 300;
`;
