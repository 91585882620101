import styled from 'styled-components';

import { Colors, Breakpoints, Transitions } from '@common/theme';
import { OnePageSection } from '@components/OnePageSection';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import { SectionTitle, SectionLabel } from '@components/Text/styles';

import { Swiper, SwiperSlide } from 'swiper/react';

export const Slider = styled(Swiper)`
  --swiper-pagination-color: ${Colors.primary};
  padding-bottom: 60px;
  margin-bottom: 60px;

  ${Breakpoints.lg`
    margin: 0 -1.5rem 0 -1.5rem;
    padding-bottom: 20px;

    .swiper-wrapper {
      flex-wrap: wrap;
      transform: none!important;

      .swiper-slide {
        flex: 0 0 50%;
        margin: 0!important;
        padding: 10px;
      }
    }

    .swiper-pagination {
      display: none;
    }
  `}
`;

export const Slide = styled(SwiperSlide)``;

export const Wrapper = styled(OnePageSection)`
  position: relative;
  overflow: hidden;
  padding: 20px 0;
  background-color: ${Colors.neutral300};
  opacity: 0;
  transform: translateY(-1rem);
  transition: ${Transitions.default};

  &.visible {
    opacity: 1;
    transform: none;
  }

  ${Breakpoints.lg`
    padding-top: 50px;
  `}
`;

export const Title = styled(SectionTitle)`
  margin-bottom: 1rem;
`;

export const Subtitle = styled(SectionLabel)``;
